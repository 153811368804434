import React from "react";
import { Link } from "react-router-dom";
import pemalogo from "../../img/pemalogo.svg";
import car from "../../img/car.svg";
import { useTranslation } from "react-i18next";

function NavBarLayout() {
  const { i18n } = useTranslation();
  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };
  return (
    <div
      className={`flex w-full px-4 sticky z-20 bg-white top-0 items-center justify-between py-3 lg:mb-2 sm:mb-3`}
    >
      <Link to="/">
        <img className="h-16" src={pemalogo} alt="Description" />
      </Link>
      <div className="flex items-center space-x-5 lg:space-x-8">
        <Link to="/edit">
          <img className="h-10" src={car} alt="Mon stattionnement" />
        </Link>
        <div>
          {i18n.language !== "en" && (
            <button
              className="text-xl font-bold text-customblue uppercase"
              onClick={() => changeLanguage("en")}
            >
              En
            </button>
          )}
          {i18n.language !== "fr" && (
            <button
              className="text-xl font-bold text-customblue uppercase"
              onClick={() => changeLanguage("fr")}
            >
              Fr
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default NavBarLayout;
