import axios from "axios";
import CryptoJS from "crypto-js";

const APP_ID = "KAEHeWCPvL9FCBZpGbxhNTUE2evXGdGA";
const APP_KEY = "T25dPhayJYzwtFDU";

const getNonce = () => {
  /* return CryptoJS.lib.WordArray.random(16).toString(); */
  return "random_string";
};

const getSecret = (nonce) => {
  return CryptoJS.SHA512(nonce + APP_KEY).toString(CryptoJS.enc.Hex);
};

export const getAccessToken = async () => {
  const nonce = getNonce();
  const secret = getSecret(nonce);

  const body = {
    app_id: APP_ID,
    nonce: nonce,
    secret: secret,
    grant_type: "client_credentials",
  };

  try {
    const response = await axios.post(
      "https://apis.sandbox.globalpay.com/ucp/accesstoken",
      body,
      {
        headers: {
          "x-gp-version": "2021-03-22",
          "Content-Type": "application/json",
        },
      }
    );
    console.log("Voici le token :", response.data.token);
    return response.data.token;
  } catch (error) {
    console.error("Error obtaining access token:", error);
    throw error;
  }
};

export const tokenizeCard = async (cardData, accessToken) => {
  try {
    /* const response = await axios.post(
      "https://apis.sandbox.globalpay.com/payments/token",
      cardData,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    ); */
    /* return response.data; */
    console.log("Card Tokenized !");
    const token = { token: 8123541 };
    return token;
  } catch (error) {
    console.error("Error tokenizing card:", error);
    throw error;
  }
};

export const processPayment = async (paymentData, accessToken) => {
  try {
    const response = await axios.post(
      /* "https://apis.sandbox.globalpay.com/payments", */
      `https://backend-pema.vercel.app/api/v1/payments`,
      paymentData,
      {
        headers: {
          "Content-Type": "application/json",
          /* Authorization: `Bearer ${accessToken}`, */
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error processing payment:", error);
    throw error;
  }
};
