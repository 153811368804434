import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faChevronCircleRight,
  faInfoCircle,
  faWheelchair,
  faShieldAlt,
  faShuttleVan,
  faVideo,
  faCar,
  faWarehouse,
  faCreditCard,
  faClock,
} from "@fortawesome/free-solid-svg-icons";
import "../CSS/ParkingCard.css";
import { Modal } from "react-bootstrap";
import close from "../img/close.svg";
import { useTranslation } from "react-i18next";

function ParkingCard({ parking, showParkingInfo, setShowParkingInfo }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // Carte des attributs pour la correspondance des numéros aux caractéristiques
  const ATTRIBUTES_MAP = {
    1: "Sécurité 24/7",
    2: "Navette gratuite",
    3: "Lavage de voiture disponible",
    4: "Couvert",
    5: "Surveillance vidéo",
    6: "Ouvert 24h",
    7: "Prépaiement en ligne",
    8: "Stationnement à long terme",
    9: "Accessible aux handicapés",
  };

  // Carte des icônes pour les caractéristiques
  const ATTRIBUTES_ICONS = {
    1: faShieldAlt,
    2: faShuttleVan,
    3: faCar,
    4: faWarehouse,
    5: faVideo,
    6: faClock,
    7: faCreditCard,
    8: faWarehouse,
    9: faWheelchair,
  };

  const goCheckout = (parkingId) => {
    /* navigate(`/checkout/${parkingId}`, { state: { parking } }); */
    navigate(
      `/checkout/${parkingId}?price=${parking.prix}&startDate=${parking.startDate}&endDate=${parking.endDate}`,
    );
  };

  let attributsList = Array.isArray(parking.attributs) ? parking.attributs : [];

  return (
    <div
      className={` left-0  w-screen m-w  bg-white lg:h-[90vh] top-[96px] overflow-y-scroll  z-10 ${showParkingInfo ? "translate-x-0 absolute" : "translate-x-full fixed"}  transition transform duration-500 ease-in-out px-4 pb-11 flex`}
    >
      <div className="w-full lg:px-14  h-full flex flex-col items-center justify-center">
        <button
          onClick={() => setShowParkingInfo(false)}
          className="flex items-center  w-full justify-end lg:mb-5"
        >
          <img className="h-10 lg:h-8" src={close} alt="next" />
        </button>
        <div className="flex flex-col lg:flex-row lg:space-x-10 w-full">
          <img
            className="lg:w-full mt-3"
            src={parking.imageLink}
            alt={parking.nom}
          />
          <div className="flex flex-col space-y-4 lg:space-y-10 w-full">
            <div className="flex items-end justify-between  mt-4">
              <h5 className="font-semibold text-2xl ">{parking.nom}</h5>
              <button className="text-customblue" onClick={handleShow}>
                <FontAwesomeIcon icon={faInfoCircle} size="xl" />
              </button>
            </div>
            <span className="text-4xl font-bold">{parseFloat(parking.prix).toFixed(2)}$</span>
            <div className="text-lg">
              <p>
                <strong>{t('home:ParkingCard:dateAndHour')}</strong> {parking.startDate}
              </p>
              <p>
                <strong>{t('home:ParkingCard:validTill')}</strong> {parking.valide}
              </p>
              {/* <p>
              <strong>Date et heure de sortie:</strong> {parking.endDate}
            </p> */}
              <p>
                <strong>{t('home:ParkingCard:duration')}</strong> {parking.duree}
              </p>
            </div>
            <div className="text-lg">
              <p>{parking.adresse}</p>
              <a href="https://www.google.com/maps/place/1000+De+La+Gaucheti%C3%A8re,+1000+Rue+De+la+Gaucheti%C3%A8re+O,+Montr%C3%A9al,+QC+H3B+4W5/@45.499409,-73.5664085,16.42z/data=!4m15!1m8!3m7!1s0x4cc91a5d1e3fb39d:0x7934c4a99504768b!2s1000+De+La+Gaucheti%C3%A8re,+1000+Rue+De+la+Gaucheti%C3%A8re+O,+Montr%C3%A9al,+QC+H3B+4W5!3b1!8m2!3d45.4981757!4d-73.5663859!16zL20vMDRrbHhr!3m5!1s0x4cc91a5d1e3fb39d:0x7934c4a99504768b!8m2!3d45.4981757!4d-73.5663859!16zL20vMDRrbHhr?entry=ttu">
                {t('home:ParkingCard:seeOnMap')} <FontAwesomeIcon icon={faArrowRight} />
              </a>
            </div>
            <button
              className="bg-customblue text-white py-4 px-4 rounded-md text-xl flex space-x-3 uppercase font-semibold  items-center justify-center mt-4 lg:w-6/12"
              onClick={() => goCheckout(parking.id)}
            >
              <span>{t('home:ParkingCard:prepay')}</span>
              <FontAwesomeIcon icon={faChevronCircleRight} />
            </button>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{t('home:ParkingCard:moreInformation')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ul>
            {attributsList.map((attributNum) => (
              <li key={attributNum} className="list-group-item">
                <FontAwesomeIcon
                  icon={ATTRIBUTES_ICONS[attributNum]}
                  fixedWidth
                />
                {ATTRIBUTES_MAP[attributNum] || "Attribut inconnu"}
              </li>
            ))}
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-customblue bleu-pema-fonce"
            onClick={handleClose}
          >
            {t('home:ParkingCard:close')}
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ParkingCard;
