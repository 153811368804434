import React, { useState } from "react";
import DatePicker from "react-datepicker";
import { fr } from "date-fns/locale";
import "react-datepicker/dist/react-datepicker.css";
import "../CSS/ParkingFinderForm.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronCircleRight,
  faMapMarkerAlt,
} from "@fortawesome/free-solid-svg-icons";
import ParkingCard from "./ParkingCard";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function ParkingFinderForm() {
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState(new Date());
  const [showParkingInfo, setShowParkingInfo] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [price, setPrice] = useState(20.7);

  const generateParkingInfo = (startDate) => {
    const beginDate = new Date(startDate);
    const endDate = new Date(beginDate);
    endDate.setDate(beginDate.getDate() + 1);
    beginDate.setHours(8, 0, 0); // Set start time to 8:00 AM
    endDate.setHours(8, 0, 0); // Set end time to 8:00 AM the next day

    return {
      id: 1,
      nom: "1000 De La Gauchetière",
      adresse: "1000 Rue De la Gauchetière O, Montréal, QC H3B 4W5",
      imageLink:
        "https://img.lapresse.ca/924x615/201705/12/1401641-caisse-depot-mettra-bientot-vente.jpg",
      prix: price,
      startDate: beginDate.toLocaleString("fr-FR", {
        dateStyle: "medium",
        timeStyle: "short",
      }),
      endDate: endDate.toLocaleString("fr-FR", {
        dateStyle: "medium",
        timeStyle: "short",
      }),
      duree: "24 heures",
      valide: endDate.toLocaleString("fr-FR", {
        dateStyle: "medium",
        timeStyle: "short",
      }),
      lat: 45.49817526069414,
      lng: -73.56637633437992,
    };
  };

  const handleSubmit = () => {
    setShowParkingInfo(true);
  };

  /* const handleSubmit = () => {
    navigate("/checkout", { state: { parkingInfo } });
  }; */

  const parkingInfo = generateParkingInfo(startDate);
  console.log(parkingInfo);

  return (
    <div className={`w-full pt-10 pb-7 sm:pb-10 lg:px-14 overflow-hidden`}>
      <div className="px-4 flex flex-col space-y-5 w-full pb-7">
        <h2 className="font-bold uppercase text-3xl">
          {t('home:ParkingFinderForm:formTitle')}
        </h2>
        <p className="font-semibold mt-3 text-lg">
          <FontAwesomeIcon icon={faMapMarkerAlt} /> 1000 de la Gauchetière,
          Montréal
        </p>

        <form
          className="pt-10 w-full flex flex-col max-w-[500px] lg:max-w-[800px]"
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <div className="flex flex-col w-full">
            <label
              htmlFor="beginDate"
              className="text-customblue font-semibold pb-2 text-lg"
            >
              {t('home:ParkingFinderForm:entryDate')}
            </label>

            <DatePicker
              id="beginDate"
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              locale={fr}
              dateFormat="dd/MM/yyyy"
              className="py-3  text-base px-3 border border-gray-300 rounded-md w-10/12 lg:w-full"
            />
          </div>

          <div className="flex flex-col mt-4 w-full">
            <label
              htmlFor="promoCode"
              className="text-customblue font-semibold pb-2 text-lg"
            >
              {t('home:ParkingFinderForm:promoCode')}
            </label>
            <input
              type="text"
              className="py-3 px-3 border border-gray-300 rounded-md w-10/12 lg:w-full"
              id="promoCode"
              placeholder={t('home:ParkingFinderForm:promoCodePlaceholder')}
            />
          </div>

          <button
            type="submit"
            className="bg-customblue text-white py-4 px-4 mt-5 rounded-md text-lg flex space-x-5 items-center lg:w-6/12 justify-center "
          >
            <span>{t('home:ParkingFinderForm:btnGetPrice')} </span>
            <FontAwesomeIcon icon={faChevronCircleRight} size={"1.2x"} />
          </button>
          <Link
            to="/edit"
            className="bg-customblue text-white py-4 px-4 mt-4 lg:mt-5 rounded-md text-lg flex space-x-5 items-center lg:w-6/12 justify-center"
          >
            <span className="no-underline">{t("home:Navbar:myParking")}</span>
          </Link>
        </form>
      </div>

      <ParkingCard
        parking={parkingInfo}
        showParkingInfo={showParkingInfo}
        setShowParkingInfo={setShowParkingInfo}
      />
    </div>
  );
}

export default ParkingFinderForm;
