import React from "react";
import NavBarLayout from "./NavBarLayout";
import bg from "../../img/backgroundtest.png";

function Layout({ children, isBg }) {
  return (
    <div
      style={{
        backgroundImage: `url(${isBg ? bg : ""})`, // Correct template literal usage
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
      className="bg-white w-screen font-poppins flex  flex-col  lg:items-center"
    >
      <NavBarLayout isBg={isBg} />
      {children}
    </div>
  );
}

export default Layout;
