// src/pages/Checkout.js
import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import { ParkingTicket } from "../components/indexComponents";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCcVisa,
  faCcMastercard,
  faCcPaypal,
} from "@fortawesome/free-brands-svg-icons";
import {
  getAccessToken,
  tokenizeCard,
  processPayment,
} from "../services/globalPaymentAPI";
import Layout from "../components/basics/Layout";
import { useTranslation } from "react-i18next";

function scrollToTop() {
  window.scrollTo({
      top: 0,
      behavior: 'smooth' // Défilement fluide
  });
}


function Checkout() {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  // const price = searchParams.get("price");
  const startDate = searchParams.get("startDate");
  const endDate = searchParams.get("endDate");

  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [total, setTotal] = useState();
  const [cardType, setCardType] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [expiry, setExpiry] = useState("");
  const [cvv, setCvv] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [paymentConfirmed, setPaymentConfirmed] = useState(false);
  const [showPaymentForm, setShowPaymentForm] = useState(true);
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");

  useEffect(() => {
    scrollToTop();

    const fetchTotal = () => {
      const fakeTotal = 20.7;
      setTotal(fakeTotal);
    };
    fetchTotal();

    const initializeHostedFields = async () => {
      const accessToken = await getAccessToken();
      window.GlobalPayments.configure({
        accessToken: accessToken,
        apiVersion: "2021-03-22",
        env: "sandbox",
      });

      const cardForm = window.GlobalPayments.ui.form({
        fields: {
          "card-number": {
            target: "#card-number",
            placeholder: "•••• •••• •••• ••••",
          },
          "card-expiration": {
            target: "#card-expiration",
            placeholder: "MM / YYYY",
          },
          "card-cvv": { target: "#card-cvv", placeholder: "•••" },
          "card-holder-name": {
            target: "#card-holder-name",
            placeholder: "Jane Smith",
          },
          submit: { target: "#submit-button", value: "Pay Now" },
        },
        styles: {
          // Ajoutez vos styles personnalisés ici
        },
      });

      cardForm.ready(() => {
        console.log("Registration of all credit card fields occurred");
      });

      cardForm.on("token-success", (resp) => {
        const token = document.createElement("input");
        token.type = "hidden";
        token.name = "payment-reference";
        token.value = resp.paymentReference;
        const form = document.getElementById("payment-form");
        form.appendChild(token);
        form.submit();
      });

      cardForm.on("token-error", (resp) => {
        console.error("Token generation error:", resp);
      });
    };

    initializeHostedFields();
  }, []);

  const parking = {
    id: 52415,
    name: "parking 1",
    adresse: "Adresse 1",
    imageLink:
      "https://images.unsplash.com/photo-1588362951121-3ee319b018b2?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    attributs: [2, 5, 8],
    fromAirport: 2,
    price: "56",
    lng: "2.2945",
    lat: "48.8584",
    taxes: "8.40",
    serviceHours: "2 février 13h - 5 février 16h",
    location: "1000 Rue De la Gauchetière O, Montréal, QC H3B 4W5",
    beginDate: startDate,
    startTime: "13h00",
    endDate: endDate,
    endTime: "16h00",
  };

  // Fonction de validation des champs email et téléphone
  const validateForm = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^[0-9]{3}-[0-9]{3}-[0-9]{4}$/;

    let isValid = true;

    if (!emailRegex.test(email)) {
      setEmailError("Veuillez entrer une adresse email valide.");
      setTimeout(() => {
        setEmailError("");
      }, 2500);
      isValid = false;
    } else {
      setEmailError("");
    }

    if (!phoneRegex.test(phoneNumber)) {
      setPhoneError(
        "Veuillez entrer un numéro de téléphone valide (format: 123-456-7890).",
      );
      setTimeout(() => {
        setPhoneError("");
      }, 2500);
      isValid = false;
    } else {
      setPhoneError("");
    }

    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    setIsLoading(true);

    try {
      // Obtenir le jeton d'accès
      const accessToken = await getAccessToken();
      console.log("Voici le token :", accessToken);

      // Tokeniser la carte
      const cardData = {
        card_number: cardNumber,
        expiry_date: expiry,
        card_type: cardType,
        cvv: cvv,
      };
      const tokenResponse = await tokenizeCard(cardData, accessToken);
      const token = tokenResponse.token;

      // Effectuer le paiement
      const paymentData = {
        token: token,
        amount: total,
        currency: "CAD",
        email: email,
        phone: phoneNumber,
        merchantId: "MER_7e3e2c7df34f42819b3edee31022ee3f",
      };
      const paymentResponse = await processPayment(paymentData, accessToken);
      console.log(paymentResponse.status);

      if (paymentResponse.responseCode === "00") {
        const ticketData = {
          email: email,
          phone: phoneNumber,
          beginDate: parking.beginDate,
          endDate: parking.endDate,
          price: total,
        };

        const ticketResponse = await axios.post(
          "http://localhost:3001/api/v1/ticket",
          ticketData,
          {
            headers: {
              "Content-Type": "application/json",
            },
          },
        );

        if (ticketResponse.status === 201) {
          setPaymentConfirmed(true);
          setShowPaymentForm(false);
          console.log("Ticket created and sent to:", email);
        } else {
          console.error("Failed to create ticket:", ticketResponse.data);
        }
      } else {
        console.error("Payment failed:", paymentResponse);
      }
    } catch (error) {
      console.error("Payment error:", error);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <Layout>
      <div className="w-full  flex flex-col xl:flex-row justify-between sm:items-center lg:items-start lg:p-3 sm:p-1">
        <div className="bg-white rounded xl:w-9/12">
          {showPaymentForm ? (
            <form
              onSubmit={handleSubmit}
              className="bg-white text-base space-y-4 lg:m-4 m-2 p-4 rounded-2xl shadow-xl border border-gray-400"
            >
              <svg
                width="80"
                height="70"
                viewBox="0 0 500 500"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="ticket-logo my-2"
              >
                <path
                  d="M500 250.003C500 388.073 388.071 500 250 500C111.929 500 0 388.073 0 250.003C0 111.934 111.929 0.000235209 250 0.000235209C388.071 0.000235209 500 111.934 500 250.003Z"
                  fill="#003595"
                />
                <path
                  d="M296.064 105.697H160.107V155.809H226.43V155.993H282.733C300.001 155.993 328.909 155.993 328.909 205.174C328.909 253.957 300.001 253.957 282.733 253.957H207.947V253.906H160.107V395.071H210.53V304.24H296.064C346.278 304.24 378.724 265.357 378.724 205.174C378.724 145.675 345.505 105.697 296.064 105.697Z"
                  fill="white"
                />
                <path
                  d="M250.862 226.114H160.1V183.61H250.862V226.114Z"
                  fill="#00AEEF"
                />
              </svg>
              <h2>{t('home:Checkout:paymentDetail')}</h2>
              <p>
                {t('home:Checkout:fillForm')}
              </p>

              <div className="mb-3">
                <label htmlFor="email" className="form-label">
                  {t('home:Checkout:email')}
                </label>
                <input
                  type="email"
                  className="py-3 px-3 border border-gray-300 rounded-md w-10/12 xl:w-full"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder={t('home:Checkout:emailPlaceholder')}
                />
                {emailError && <div className="text-danger">{emailError}</div>}
              </div>

              <div className="mb-3">
                <label htmlFor="phoneNumber" className="form-label">
                {t('home:Checkout:phone')}
                </label>
                <input
                  type="tel"
                  className="py-3 px-3 border border-gray-300 rounded-md w-10/12 lg:w-full"
                  id="phoneNumber"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  placeholder="123-456-7890"
                />
                {phoneError && <div className="text-danger">{phoneError}</div>}
              </div>

              <div className="payment-methods my-3 w-100">
                <span className="mb-2 d-block">
                {t('home:Checkout:paymentMethod')}
                </span>
                <div className="d-flex justify-content-left align-items-center">
                  <FontAwesomeIcon icon={faCcVisa} size="3x" className="mx-2" />
                  <FontAwesomeIcon
                    icon={faCcMastercard}
                    size="3x"
                    className="mx-2"
                  />
                  <FontAwesomeIcon
                    icon={faCcPaypal}
                    size="3x"
                    className="mx-2"
                  />
                </div>
              </div>

              <div className="mb-3">
                <label htmlFor="cardType" className="form-label">
                  {t('home:Checkout:cardType')}
                </label>
                <select
                  className="form-select py-3 px-3 border border-gray-300 rounded-md w-10/12 lg:w-full"
                  id="cardType"
                  value={cardType}
                  onChange={(e) => setCardType(e.target.value)}
                >
                  <option value="">{t('home:Checkout:selectCardType')}</option>
                  <option value="VISA">Visa</option>
                  <option value="MC">MasterCard</option>
                  <option value="AMEX">American Express</option>
                </select>
              </div>

              <div className="mb-3">
                <label htmlFor="cardNumber" className="form-label">
                {t('home:Checkout:cardNumber')}
                </label>
                <input
                  type="text"
                  className="py-3 px-3 border border-gray-300 rounded-md w-10/12 lg:w-full"
                  id="cardNumber"
                  value={cardNumber}
                  onChange={(e) => setCardNumber(e.target.value)}
                  placeholder={t('home:Checkout:cardNumber')}
                />
              </div>

              <div className="row">
                <div className="col-md-6 mb-3">
                  <label htmlFor="expiry" className="form-label">
                    Expiration
                  </label>
                  <input
                    type="text"
                    className="py-3 px-3 border border-gray-300 rounded-md w-10/12 lg:w-full"
                    id="expiry"
                    value={expiry}
                    onChange={(e) => setExpiry(e.target.value)}
                    placeholder={t('home:Checkout:expirationPlaceholder')}
                  />
                </div>

                <div className=" flex flex-col mb-3">
                  <label htmlFor="cvv" className="form-label">
                    CVV
                  </label>
                  <input
                    type="text"
                    className="py-3 px-3 border border-gray-300 rounded-md w-10/12 lg:w-full"
                    id="cvv"
                    value={cvv}
                    onChange={(e) => setCvv(e.target.value)}
                    placeholder="CVV"
                  />
                </div>
              </div>

              <div>
                <button
                  className="py-3 px-3 bg-customblue text-white rounded-md w-full text-lg"
                  type="submit"
                  disabled={isLoading}
                >
                  {isLoading ? "Chargement..." : `${t('home:Checkout:pay')} ${Math.round(total)}$`}
                </button>
              </div>

              {paymentConfirmed && (
                <div className="alert alert-success mt-3" role="alert">
                  {t('home:Checkout:paymentAchieved')}
                </div>
              )}

              <div className="mb-5">
                <small>{t('home:Checkout:safePayment')}</small>
              </div>
            </form>
          ) : (
            <div className="d-flex flex-column gap-2 p-2 ">
              <button className="btn btn-customblue bleu-pema-fonce">
              {t('home:Checkout:seeRoute')}
              </button>
              {paymentConfirmed && (
                <div className="alert alert-success mt-3" role="alert">
                  {t('home:Checkout:paymentAchieved')}
                </div>
              )}
            </div>
          )}
        </div>

        <ParkingTicket parking={parking} />
      </div>
    </Layout>
  );
}

export default Checkout;
